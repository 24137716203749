import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';

const ProductCard = ({ item }) => {
  const history = useHistory();

  return (
    <Container>
      <ImageWrapper src={item?.src} alt="product" onClick={() => history.push(`/product/${item?.id}`)}></ImageWrapper>
      <RowBetween>
        <div>
          <Text size="18px" color={Colors.gray} bold="400">
            {item?.name}
          </Text>
        </div>
        {item?.new ? <NewLabel>New</NewLabel> : <></>}
      </RowBetween>
      <Text size="18px" color={Colors.gray} bold="400">
        $ {item?.price}
      </Text>
    </Container>
  );
};

export default ProductCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
`;
const ImageWrapper = styled.img`
  width: 275px;
  height: 275px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
`;
const NewLabel = styled.div`
  background: ${Colors.green};
  padding: 5px 20px;
  color: ${Colors.white};
`;
const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.p<{ size: string; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }
`;
