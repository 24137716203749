import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import PurpleButton from 'components/PurpleButton';

const ForgotPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };
  const handleClickSend = () => {
    history.push('/reset-password');
  };

  return (
    <Container>
      <RowCenter style={{ height: '100%' }}>
        <Wrapper>
          <Text size={28} color={Colors.black} bold="600">
            Forgot Password
          </Text>
          <TextInput>
            <input value={email} type={'text'} placeholder={'Email'} onChange={handleChangeEmail} autoComplete="off" />
          </TextInput>
          <PurpleButton onClick={handleClickSend} label="Send" />
          <RowCenter style={{ gap: '20px' }}>
            <Text size={14} color={Colors.textDark} bold="600">
              Already have an account?
            </Text>
            <Text size={14} color={Colors.blue} bold="600" isHover onClick={() => history.push('/sign-in')}>
              Sign In
            </Text>
          </RowCenter>
        </Wrapper>
      </RowCenter>
    </Container>
  );
};

export default ForgotPassword;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${Colors.white};
`;
const Wrapper = styled.div`
  width: 380px;
  min-width: 300px;
  margin: 20px;
  padding: 40px 20px;
  box-shadow: gray 0px 0px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const TextInput = styled.div`
  flex: 1;
  position: relative;
  padding: 8px 20px;
  border: 1px solid ${Colors.textGray};
  border-radius: 4px;
  z-index: 1;
  & input {
    background: transparent;
    border: none;
    width: 100%;
    box-shadow: none;
    outline: none;
    color: ${Colors.black};
    font-size: 16px;
    &::placeholder {
      color: #a8a8a8;
    }
  }
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  text-align: center;
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  cursor: ${({ isHover }) => (isHover ? 'pointer' : '')};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.4 : size < 20 ? size : size * 0.7}px`};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.5 : size < 20 ? size : size * 0.8}px`};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.7 : size < 20 ? size : size * 0.9}px`};
  }

  @media only screen and (min-width: 1200px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.9 : size < 20 ? size : size}px`};
  }

  @media only screen and (min-width: 1400px) {
    font-size: ${({ size }) => `${size}px`};
  }
`;
const RowCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
