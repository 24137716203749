import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SPHYNX_PAY_BACKEND_URL, SPHYNX_PAY_FRONTEND_URL } from 'constants/index';
import { onRemoveItemFromCart } from 'store/actions/cart';
import { Colors } from 'constants/Colors';
import PurpleButton from 'components/PurpleButton';
import toast from 'react-hot-toast';
import axios from 'axios';

function randomString(length, chars) {
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

const CheckOut = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const transactionId = randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
  const shoppingItems = useSelector((state: any) => state?.cart?.items);
  const totalPrice = useSelector((state: any) => state?.cart?.total);

  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
  });
  const handleChangeUserInfo = (type, e) => {
    let _userInfo = { ...userInfo };
    _userInfo[type] = e.target.value;
    setUserInfo(_userInfo);
  };
  const handleDeleteItem = index => {
    dispatch(onRemoveItemFromCart(index));
  };
  const handleBuyProduct = async () => {
    if (totalPrice === 0) {
      toast.error('Please add product!');
      return;
    } else if (!userInfo?.name || !userInfo?.email || !userInfo?.phoneNumber || !userInfo?.address) {
      toast.error('Please enter all information!');
      return;
    }

    const _description = shoppingItems
      .map(_item => `${_item.name}(${_item?.price}) * ${_item.quantity} = ${_item?.price * _item.quantity}`)
      .join(`%0A`);

    const data = {
      transaction_id: transactionId,
      name: userInfo?.name,
      email: userInfo?.email,
      phone_number: userInfo?.phoneNumber,
      address: userInfo?.address,
      products: JSON.stringify(shoppingItems),
      total_price: totalPrice * 1 + 10,
    };
    try {
      let res: any = await axios.post(`${process.env.REACT_APP_BACKEND}/product`, data);
      res = res.data;
      console.log('>>>>>>>>>>>>>> post product : ', res);
      if (res.success) {
        toast.success(res.message);
        const invoiceData = {
          name: userInfo?.name,
          email: userInfo?.email,
          amount: `${totalPrice * 1 + 10}`,
          description: _description,
          currency: 'USD',
        };

        await axios
          .post(`${SPHYNX_PAY_BACKEND_URL}/createInvoiceSub`, invoiceData)
          .then(res => {
            console.log('res', res);

            setTimeout(() => {
              window.location.href = `${SPHYNX_PAY_FRONTEND_URL}/create-payment?invoiceNo=${res.data}&merchantCallback=${window.location.origin}/transaction/${transactionId}?invoiceNo=${res.data}`;
            }, 1000);
          })
          .catch(err => {
            console.error('Create invoice error: ', err);
          });
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      console.log('Error: handleBuyProduct : ', err);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Container>
      <ContainWrapper>
        <Text size={24} color={Colors.black} bold="700">
          CheckOut
        </Text>
        <TextInput>
          <input
            value={userInfo.name}
            type={'text'}
            placeholder={'Name'}
            onChange={e => handleChangeUserInfo('name', e)}
            autoComplete="off"
          />
        </TextInput>
        <TextInput>
          <input
            value={userInfo.email}
            type={'text'}
            placeholder={'Email'}
            onChange={e => handleChangeUserInfo('email', e)}
            autoComplete="off"
          />
        </TextInput>
        <TextInput>
          <input
            value={userInfo.phoneNumber}
            type={'text'}
            placeholder={'Phone Number'}
            onChange={e => handleChangeUserInfo('phoneNumber', e)}
            autoComplete="off"
          />
        </TextInput>
        <TextInput>
          <input
            value={userInfo.address}
            type={'text'}
            placeholder={'Address'}
            onChange={e => handleChangeUserInfo('address', e)}
            autoComplete="off"
          />
        </TextInput>
        {shoppingItems?.map((item, index) => (
          <ShopItemWrapper key={index}>
            <RowStart>
              <img src={item?.src} alt="product" style={{ cursor: 'pointer' }} width={70} height={70} />
              <ColumnBetween style={{ flex: 1 }}>
                <Text size={18} color={Colors.black} bold="600">
                  {item?.name}
                </Text>
                <Text size={16} color={Colors.black} bold="500">
                  {item?.description}
                </Text>
                <RowStart>
                  <RowStartProperty>
                    <Text size={16} color={Colors.black} bold="400">
                      Size :
                    </Text>
                    <SizeButton selected={true}>{item?.size}</SizeButton>
                  </RowStartProperty>
                  <RowStartProperty>
                    <Text size={16} color={Colors.black} bold="400">
                      Color :
                    </Text>
                    <ColorButton selected={true} bgColor={item?.color}></ColorButton>
                  </RowStartProperty>
                </RowStart>
              </ColumnBetween>
            </RowStart>
            <RowStart>
              <Text size={18} color={Colors.black} bold="600" style={{ minWidth: '72px' }}>
                $ {item?.price} ( {item?.quantity} )
              </Text>
              <DeleteIcon
                style={{ cursor: 'pointer' }}
                width={22}
                height={22}
                onClick={() => handleDeleteItem(index)}
              />
            </RowStart>
          </ShopItemWrapper>
        ))}
        <Text size={18} color={Colors.black} bold="600" style={{ textAlign: 'right' }}>
          Total Price : {`$ ${totalPrice}`} + $ 10 (Delivery)
        </Text>
        <PurpleButton label="Buy" onClick={handleBuyProduct} />
      </ContainWrapper>
    </Container>
  );
};

export default CheckOut;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 232px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
`;
const ContainWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;
`;
const ShopItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-end;
    flex-wrap: wrap;
  }
`;
const ColumnBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 6px;
`;
const RowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
const RowStartProperty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;
const SizeButton = styled.div<{ selected?: boolean }>`
  min-width: 40px;
  height: 34px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.black};
  background: ${({ selected }) => (selected ? Colors.black : Colors.white)};
  color: ${({ selected }) => (selected ? Colors.white : Colors.black)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ColorButton = styled.div<{ selected?: boolean; bgColor?: string }>`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ selected }) => (selected ? Colors.black : 'transparent')};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const TextInput = styled.div`
  flex: 1;
  position: relative;
  padding: 8px 20px;
  border: 1px solid ${Colors.textGray};
  border-radius: 4px;
  z-index: 1;
  & input {
    background: transparent;
    border: none;
    width: 100%;
    box-shadow: none;
    outline: none;
    color: ${Colors.black};
    font-size: 16px;
    &::placeholder {
      color: #a8a8a8;
    }
  }
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  // text-align: center;
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  cursor: ${({ isHover }) => (isHover ? 'pointer' : '')};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.4 : size < 20 ? size : size * 0.7}px`};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.5 : size < 20 ? size : size * 0.8}px`};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.7 : size < 20 ? size : size * 0.9}px`};
  }

  @media only screen and (min-width: 1200px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.9 : size < 20 ? size : size}px`};
  }

  @media only screen and (min-width: 1400px) {
    font-size: ${({ size }) => `${size}px`};
  }
`;
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;
const CheckBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  & input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  & input[type='checkbox']:checked {
    accent-color: ${Colors.purple};
    color: ${Colors.white};
  }
`;
const FixedFilterWrapper = styled.div`
  position: fixed;
  left: 0;
  top: calc(50vh - 12px);
  padding: 8px 8px 6px 6px;
  background: white;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
`;
const FixedFillterBar = styled.div<{ open: boolean }>`
  position: fixed;
  left: ${({ open }) => (open ? '0px' : '-300px')};
  top: 81px;
  width: 300px;
  height: calc(100vh - 81px);
  background: ${Colors.white};
  transition: all 0.2s ease-in-out;
  z-index: 999;
  border-right: 1px solid ${Colors.gray};
`;
