import React from 'react';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';

const PurpleButton = ({ label = '', onClick = () => {}, style = {} }) => {
  return (
    <Container style={style} onClick={onClick}>
      {label}
    </Container>
  );
};

export default PurpleButton;

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
  background: ${Colors.purple};
  border-radius: 4px;
  font-size: 16px;
  color: ${Colors.white};
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
