import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from 'views/Home';
import Product from 'views/Product';
import CheckOut from 'views/CheckOut';
import AllTransactions from 'views/AllTransactions';
import Transaction from 'views/Transaction';
import SignIn from 'views/SignIn';
import SignUp from 'views/SignUp';
import ForgotPassword from 'views/ForgotPassword';
import VerifyCode from 'views/VerifyCode';
import ResetPassword from 'views/ResetPassword';
import NotFound from 'views/NotFound';
import RouteWithLayout from 'layouts/RouteWithLayout';
import MainLayout from 'layouts/MainLayout';
import ProductDetail from 'views/ProductDetail';

function App() {
  return (
    <Router>
      <Switch>
        <RouteWithLayout component={Home} exact strict layout={MainLayout} path="/" />
        <RouteWithLayout component={Product} exact strict layout={MainLayout} path="/product" />
        <RouteWithLayout component={CheckOut} exact strict layout={MainLayout} path="/check-out" />
        <RouteWithLayout component={AllTransactions} exact strict layout={MainLayout} path="/all-transactions" />
        <RouteWithLayout component={Transaction} exact strict layout={MainLayout} path="/transaction/:id" />
        <RouteWithLayout component={ProductDetail} exact strict layout={MainLayout} path="/product/:id" />
        <Route component={SignIn} exact strict path="/sign-in" />
        <Route component={SignUp} exact strict path="/sign-up" />
        <Route component={ForgotPassword} exact strict path="/forgot-password" />
        <Route component={VerifyCode} exact strict path="/verify-code" />
        <Route component={ResetPassword} exact strict path="/reset-password/:verifyCode" />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
