import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store/index';
import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <Toaster position="top-right" reverseOrder={false} />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
