import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';
import cardReducer from './cart';

// WHITELIST
const persistConfig = {
  key: 'root',
  storage: session,
  whitelist: ['cart'], // only card will be persisted
};

const rootReducer = combineReducers({
  cart: cardReducer,
  // product: productsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
