import * as C from "../types/cart";

const onAddItemtoCart = (item) => ({
  type: C.ADD_ITEM,
  payload: { item },
});

const onRemoveItemFromCart = (index) => ({
  type: C.REMOVE_ITEM,
  payload: { index },
});

const onRemoveAllItemFromCart = () => ({
  type: C.REMOVE_ALL_ITEM,
  payload: {},
});

export { onAddItemtoCart, onRemoveItemFromCart, onRemoveAllItemFromCart };
