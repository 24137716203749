import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as MainLogoIcon } from 'images/bottom-logo.svg';
import { ReactComponent as WorldIcon } from 'images/world.svg';
import { ReactComponent as GooglePlayIcon } from 'images/google-play-button.svg';
import { ReactComponent as AppStoreIcon } from 'images/app-store-button.svg';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';

const BottomBar = () => {
  const history = useHistory();

  return (
    <BottomPage>
      <Container>
        <MainLogoIcon onClick={() => history.push('/')} style={{ cursor: 'pointer' }} width={240} height={40} />
        <Divider />
        <RowBetween>
          <RowCenter>
            <ColumnStart style={{ paddingTop: '30px' }}>
              <RowStart>
                <WorldIcon style={{ cursor: 'pointer' }} width={24} height={24} />
                <a target='_black' href='https://thesphynx.co/swap/56' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Text bold="400" size="20px" isHover color={Colors.white} style={{ marginLeft: '10px' }}>
                    thesphynx.co
                  </Text>
                </a>
              </RowStart>
              <Text bold="400" size="20px" isHover color={Colors.white} style={{ margin: '33px 0px 10px 0px' }}>
                Download Sphynx DeFi on :
              </Text>
              <RowStart style={{ gap: '20px' }}>
                <a target='_black' href='https://play.google.com/store/apps/details?id=com.sphynx' rel="noopener noreferrer">
                  <GooglePlayIcon style={{ cursor: 'pointer' }} width={110} height={30} />
                </a>
                <a target='_black' href='https://apps.apple.com/nz/app/sphynx-defi-app/id1626361848' rel="noopener noreferrer">
                  <AppStoreIcon style={{ cursor: 'pointer' }} width={110} height={30} />
                </a>
              </RowStart>
            </ColumnStart>
          </RowCenter>
          <RowStart style={{ gap: '20px', paddingTop: '30px' }}>
            {/* <ColumnStart>
              <Text bold="400" size="20px" isHover color={Colors.textGray} style={{ margin: '10px 0px' }}>
                New Trending
              </Text>
              <Text bold="400" size="20px" isHover color={Colors.textGray} style={{ margin: '10px 0px' }}>
                New Arrival
              </Text>
              <Text bold="400" size="20px" isHover color={Colors.textGray} style={{ margin: '10px 0px' }}>
                Best Seller
              </Text>
            </ColumnStart>
            <ColumnStart>
              <Text bold="400" size="20px" isHover color={Colors.textGray} style={{ margin: '10px 0px' }}>
                About Us  
              </Text>
              <Text bold="400" size="20px" isHover color={Colors.textGray} style={{ margin: '10px 0px' }}>
                Career
              </Text>
              <Text bold="400" size="20px" isHover color={Colors.textGray} style={{ margin: '10px 0px' }}>
                Our Store
              </Text>
            </ColumnStart> */}
          </RowStart>
        </RowBetween>
      </Container>
    </BottomPage>
  );
};

export default BottomBar;

const BottomPage = styled.div`
  background: ${Colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 80px 20px;
  @media (max-width: 768px) {
  }
`;
const Divider = styled.div`
  border: 2px solid ${Colors.white};
  width: 400px;
  @media (max-width: 768px) {
    width: 300px;
  }
`;
const ColumnStart = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const RowCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const Text = styled.p<{ size: string; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }
`;
