import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import { ReactComponent as MainLogoIcon } from 'images/main-logo.svg';
import { ReactComponent as LogoIcon } from 'images/logo.svg';
import { ReactComponent as SearchIcon } from 'images/search-icon.svg';
import { ReactComponent as ShopIcon } from 'images/shop-icon.svg';
import { ReactComponent as MenuIcon } from 'images/menu-icon.svg';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { ReactComponent as DeleteIcon } from 'images/delete-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { onRemoveItemFromCart, onRemoveAllItemFromCart } from 'store/actions/cart';
import useIsMobile from 'hooks/useIsMobile';
import PurpleButton from './PurpleButton';

const TopBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [openMenu, setOpenMenu] = useState(false);
  const [openShopMenu, setOpenShopMenu] = useState(false);
  const menuRef = useRef(null);
  const shoppingItems = useSelector((state: any) => state?.cart?.items);
  const totalPrice = useSelector((state: any) => state?.cart?.total);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleClickCart = () => {
    setOpenShopMenu(!openShopMenu);
  };
  const handleDeleteItem = index => {
    dispatch(onRemoveItemFromCart(index));
  };
  const handleDeleteAll = () => {
    dispatch(onRemoveAllItemFromCart());
  };
  const handleCheckOut = () => {
    history.push('/check-out');
    setOpenShopMenu(false);
  };

  const ShoppingCartContainer = () => {
    return (
      <ShopContainer openMenu={openShopMenu}>
        <ShopWrapper>
          <CloseIcon
            style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
            width={30}
            height={30}
            onClick={() => setOpenShopMenu(!openShopMenu)}
          />
          <Text size={'20px'} color={Colors.black} bold="700">
            Product
          </Text>
          <ContentWrapper>
            {shoppingItems?.map((item, index) => (
              <ShopItemWrapper key={index}>
                <img src={item?.src} alt="product" style={{ cursor: 'pointer' }} width={50} height={50} />
                <ColumnBetween style={{ flex: 1 }}>
                  <Text size={'16px'} color={Colors.black} bold="500">
                    {item?.name}
                  </Text>
                  <Text size={'16px'} color={Colors.black} bold="500">
                    $ {item?.price}
                  </Text>
                </ColumnBetween>
                <Text size={'18px'} color={Colors.black} bold="600">
                  {item?.quantity}
                </Text>
                <DeleteIcon
                  style={{ cursor: 'pointer' }}
                  width={22}
                  height={22}
                  onClick={() => handleDeleteItem(index)}
                />
              </ShopItemWrapper>
            ))}
          </ContentWrapper>
          {shoppingItems?.length > 0 ? (
            <>
              <PurpleButton
                onClick={handleDeleteAll}
                label="All Clear"
                style={{ width: 'fit-content', padding: '4px 12px', marginLeft: 'auto', background: 'black' }}
              />
              <Text size={'16px'} color={Colors.black} bold="600">
                Total Price : {`$ ${totalPrice}`}
              </Text>
              <PurpleButton onClick={handleCheckOut} label="Check Out" />
            </>
          ) : (
            <Text size={'16px'} color={Colors.black} bold="600">
              No products
            </Text>
          )}
        </ShopWrapper>
      </ShopContainer>
    );
  };

  return (
    <TopBarPage>
      <Container>
        {isMobile ? (
          <LogoIcon onClick={() => history.push('/')} style={{ cursor: 'pointer' }} width={40} height={40} />
        ) : (
          <MainLogoIcon onClick={() => history.push('/')} style={{ cursor: 'pointer' }} width={240} height={40} />
        )}
        <RowBetween>
          {isMobile ? (
            <>
              <MenuWrapper>
                <RowBetween>
                  {/* <SearchIcon style={{ cursor: 'pointer' }} width={25} height={25} /> */}
                  <div style={{ position: 'relative' }} onClick={handleClickCart}>
                    <ShopIcon style={{ cursor: 'pointer' }} width={25} height={25} />
                    {
                      shoppingItems.length !== 0 && (
                        <p style={{
                          fontSize: '8px',
                          background: 'red',
                          color: 'white',
                          padding: '4px',
                          borderRadius: '6px',
                          lineHeight: '4px',
                          position: 'absolute',
                          bottom: '-2px',
                          right: '-2px'
                        }}>{shoppingItems.length}</p>
                      )
                    }
                  </div>
                  {/* <MenuIcon
                    ref={menuRef}
                    onClick={() => setOpenMenu(!openMenu)}
                    style={{ cursor: 'pointer' }}
                    width={25}
                    height={25}
                  /> */}
                </RowBetween>
                <ShoppingCartContainer />
                {/* <MenuListContainer openMenu={openMenu}>
                  <TextMenu
                    onClick={() => {
                      history.push('/sign-in');
                    }}
                  >
                    Sign In
                  </TextMenu>
                  <TextMenu
                    onClick={() => {
                      history.push('/sign-up');
                    }}
                  >
                    Sign Up
                  </TextMenu>
                </MenuListContainer> */}
              </MenuWrapper>
            </>
          ) : (
            <>
              {/* <Text
                bold="400"
                size="16px"
                isHover
                color={Colors.black}
                onClick={() => {
                  history.push('/sign-in');
                }}
              >
                Sign In/Sign Up
              </Text> */}
              {/* <SearchIcon style={{ cursor: 'pointer' }} width={25} height={25} /> */}
              <div style={{ position: 'relative' }} onClick={handleClickCart}>
                <ShopIcon style={{ cursor: 'pointer' }} width={25} height={25} />
                {
                  shoppingItems.length !== 0 && (
                    <p style={{
                      fontSize: '8px',
                      background: 'red',
                      color: 'white',
                      padding: '4px',
                      borderRadius: '6px',
                      lineHeight: '4px',
                      position: 'absolute',
                      bottom: '-2px',
                      right: '-2px'
                    }}>{shoppingItems.length}</p>
                  )
                }
              </div>
              {/* <MenuIcon style={{ cursor: 'pointer' }} width={25} height={25} /> */}
              <ShoppingCartContainer />
            </>
          )}
        </RowBetween>
      </Container>
    </TopBarPage>
  );
};

export default TopBar;
const ShopItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;
`;
const ColumnBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 4px;
`;
const TopBarPage = styled.div`
  position: fixed;
  z-index: 12;
  width: 100%;
  background: ${Colors.white};
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: relative;
`;
const Text = styled.p<{ size: string; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  cursor: pointer;
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }
`;
const TextMenu = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  cursor: pointer;
  color: ${Colors.black};
  &:hover {
    opacity: 0.8;
  }
`;
const RowBetween = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;
const MenuWrapper = styled.div`
  position: relative;
`;
const MenuListContainer = styled.div<{ openMenu: boolean }>`
  position: absolute;
  flex-direction: column;
  top: 40px;
  right: 5px;
  width: 150px;
  padding: 12px;
  background: ${Colors.white};
  border-radius: 8px;
  height: inherit;
  padding: 10px;
  box-shadow: ${Colors.gray} 0px 0px 20px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${({ openMenu }) => (!openMenu ? '0' : '1')};
  visibility: ${({ openMenu }) => (!openMenu ? 'hidden' : 'visible')};
`;
const ShopContainer = styled.div<{ openMenu: boolean }>`
  position: fixed;
  right: ${({ openMenu }) => (openMenu ? '0px' : '-300px')};
  top: 81px;
  width: 300px;
  height: calc(100vh - 81px);
  background: ${Colors.white};
  transition: all 0.2s ease-in-out;
  z-index: 999;
  border-left: 1px solid ${Colors.gray};
`;
const ShopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
`;
const ContentWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 380px);
`;
