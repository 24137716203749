import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import HomeBackground1 from 'images/home-background-1.png';
import HomeBackground2 from 'images/home-background-2.png';
import HomeBackground3 from 'images/home-background-3.png';
import PurpleButton from 'components/PurpleButton';

const Home = () => {
  const history = useHistory();

  return (
    <HomePage>
      <Section1>
        <RowCenter>
          <ColumnCenter>
            <GradientText color1="#9747FF" color2="#654390" color3="#000001" size={136} bold="400">
              SPHYNX
            </GradientText>
            <Text bold="400" size={50} isHover color={Colors.gray} style={{ marginTop: '0px' }}>
              TOP COLLECTION
            </Text>
          </ColumnCenter>
        </RowCenter>
      </Section1>
      <Section2 />
      <Section3>
        <RowCenter>
          <ColumnCenter>
            <Text bold="400" size={24} isHover color={Colors.black} style={{ margin: '7px 0px' }}>
              summer essentials
            </Text>
            <Text bold="700" size={100} isHover color={Colors.black}>
              Chase The Day
            </Text>
            <Text bold="400" size={24} isHover color={Colors.black} style={{ marginTop: '20px' }}>
              Move. Explore. Bring your boldest.
            </Text>
            <Text bold="400" size={24} isHover color={Colors.black} style={{ marginTop: '7px' }}>
              Get after Web3’s endless possibilities with Sphynx Merch using Sphynx Pay.
              <br /> Pay Now using Crypto. 
            </Text>
            <PurpleButton
              label="Shop Now"
              style={{ width: 'fit-content', marginTop: '40px', fontSize: '20px' }}
              onClick={() => history.push('/product')}
            />
          </ColumnCenter>
        </RowCenter>
      </Section3>
      <Section4></Section4>
    </HomePage>
  );
};

export default Home;

const HomePage = styled.div`
  width: 100%;
  min-height: calc(100vh - 232px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
`;
const ColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const RowCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const Section1 = styled.div`
  padding: 400px 20px;
  background-image: url(${HomeBackground1});
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: contain;
  object-position: center;

  @media only screen and (max-width: 768px) {
    padding: 200px 20px;
  }

  @media only screen and (min-width: 768px) {
    padding: 250px 20px;
  }

  @media only screen and (min-width: 992px) {
    padding: 300px 20px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 350px 20px;
  }

  @media only screen and (min-width: 1400px) {
    padding: 400px 20px;
  }
`;
const Section2 = styled.div`
  padding: 150px 0px;
  background-image: url(${HomeBackground2});
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  object-position: center;

  @media only screen and (max-width: 768px) {
    padding: 70px 0px;
  }

  @media only screen and (min-width: 768px) {
    padding: 90px 0px;
  }

  @media only screen and (min-width: 992px) {
    padding: 110px 0px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 130px 0px;
  }

  @media only screen and (min-width: 1400px) {
    padding: 150px 0px;
  }
`;
const Section3 = styled.div`
  padding: 150px 20px;

  @media only screen and (max-width: 768px) {
    padding: 70px 20px;
  }

  @media only screen and (min-width: 768px) {
    padding: 90px 20px;
  }

  @media only screen and (min-width: 992px) {
    padding: 110px 20px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 130px 20px;
  }

  @media only screen and (min-width: 1400px) {
    padding: 150px 20px;
  }
`;
const Section4 = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 400px 0px;
  background-image: url(${HomeBackground3});
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: contain;
  object-position: center;

  @media only screen and (max-width: 768px) {
    padding: 150px 0px;
  }

  @media only screen and (min-width: 768px) {
    padding: 200px 0px;
  }

  @media only screen and (min-width: 992px) {
    padding: 300px 0px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 350px 0px;
  }

  @media only screen and (min-width: 1400px) {
    padding: 400px 0px;
  }
`;
const GradientText = styled.p<{ color1: string; color2: string; color3: string; size: number; bold: string }>`
  font-style: normal;
  font-family: 'Playfair Display';
  letter-spacing: 80px;
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background: ${({ color1, color2, color3 }) => `-webkit-linear-gradient(${color1}, ${color2}, ${color3})`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width: 768px) {
    letter-spacing: 15px;
    font-size: 50px;
  }

  @media only screen and (min-width: 768px) {
    letter-spacing: 30px;
    font-size: 70px;
  }

  @media only screen and (min-width: 992px) {
    letter-spacing: 40px;
    font-size: 90px;
  }

  @media only screen and (min-width: 1200px) {
    letter-spacing: 60px;
    font-size: 110px;
  }

  @media only screen and (min-width: 1400px) {
    letter-spacing: 80px;
    font-size: 136px;
  }
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  text-align: center;
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.4 : size * 0.7}px`};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.5 : size * 0.8}px`};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.7 : size * 0.9}px`};
  }

  @media only screen and (min-width: 1200px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.9 : size}px`};
  }

  @media only screen and (min-width: 1400px) {
    font-size: ${({ size }) => `${size}px`};
  }
`;
