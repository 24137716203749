import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import PurpleButton from 'components/PurpleButton';
import toast from 'react-hot-toast';
import { PRODUCT_LIST } from 'assets/mockup';
import { ReactComponent as ArrowLeft } from 'images/arrow-left.svg';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import { useDispatch } from 'react-redux';
import { onAddItemtoCart } from 'store/actions/cart';

const ProductDetail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = PRODUCT_LIST.find(item => item.id === parseInt(id));
  const [mainImageUrl, setMainImageUrl] = useState(product.subImages[0]);
  const [selectedSubImageIndex, setSelectedSubImageIndex] = useState(0);
  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);
  const [amount, setAmount] = useState(0);

  const handleChangeAmount = type => {
    if (type === 'minus') {
      amount > 0 ? setAmount(amount - 1) : setAmount(0);
    } else {
      setAmount(amount + 1);
    }
  };

  const handleClickAddToCart = () => {
    if (amount < 1) {
      toast.error('Please provide quantity!');
      return;
    }
    const data = {
      id: id,
      name: product?.name,
      description: product?.description,
      src: mainImageUrl,
      price: product?.price,
      color: product?.color[selectedColorIndex],
      size: product?.size[selectedSizeIndex],
      quantity: amount,
    };
    dispatch(onAddItemtoCart(data));
    toast.success('Successfully added to cart!');
  };

  return (
    <Container>
      <ContainWrapper>
        <ArrowLeft
          color="black"
          style={{
            cursor: 'pointer',
            alignSelf: 'flex-start',
            padding: '4px',
            border: '1px solid black',
            borderRadius: '20px',
            marginBottom: '10px',
          }}
          width={30}
          height={30}
          onClick={() => history.goBack()}
        />
        <DetailWrapper>
          <RowStart style={{ flex: 2 }}>
            <ColumnStart>
              {product?.subImages?.map((item, index) => (
                <SubImageWrapper
                  key={index}
                  selected={index === selectedSubImageIndex}
                  onClick={() => {
                    setSelectedSubImageIndex(index);
                    setMainImageUrl(item);
                  }}
                >
                  <img src={item} alt="product-detail" width={'100%'} height={'100%'} style={{ objectFit: 'cover' }} />
                </SubImageWrapper>
              ))}
            </ColumnStart>
            <MainImageWrapper style={{ flex: 1 }}>
              <img src={mainImageUrl} alt="product-detail" width={'100%'} />
            </MainImageWrapper>
          </RowStart>
          <ColumnStart style={{ flex: 1 }}>
            <Text size={40} color={Colors.black} bold="600">
              {product?.name}
            </Text>
            {/* <Text size={16} color={Colors.gray} bold="400" style={{ lineHeight: '23px' }}>
              {product?.description}
            </Text> */}
            <Text size={40} color={Colors.black} bold="600">
              $ {product?.price}
            </Text>
            <Divider />
            <Text size={17} color={Colors.black} bold="500">
              Description :
            </Text>
            <Text size={16} color={Colors.black} bold="400" style={{ lineHeight: '23px' }}>
              {product?.description}
            </Text>
            <div>
              <Text size={16} color={Colors.black} bold="400" style={{ marginBottom: '10px' }}>
                Size :
              </Text>
              <RowBetween>
                {product?.size?.map((item, index) => (
                  <SizeButton
                    key={index}
                    selected={index === selectedSizeIndex}
                    onClick={() => setSelectedSizeIndex(index)}
                  >
                    {item}
                  </SizeButton>
                ))}
              </RowBetween>
            </div>
            <div>
              <Text size={16} color={Colors.black} bold="400" style={{ marginBottom: '10px' }}>
                Color :
              </Text>
              <RowBetween>
                {product?.color?.map((item, index) => (
                  <ColorButton
                    key={index}
                    selected={index === selectedColorIndex}
                    bgColor={item}
                    onClick={() => setSelectedColorIndex(index)}
                  >
                    {index === selectedColorIndex ? <CheckIcon color="green" /> : <></>}
                  </ColorButton>
                ))}
              </RowBetween>
            </div>
            <div>
              <Text size={16} color={Colors.black} bold="400" style={{ marginBottom: '10px' }}>
                Qty :
              </Text>
              <RowBetween style={{ alignItems: 'center' }}>
                <SizeButton onClick={() => handleChangeAmount('minus')}>{'-'}</SizeButton>
                <Text size={16} color={Colors.black} bold="600">
                  {amount}
                </Text>
                <SizeButton onClick={() => handleChangeAmount('plus')}>{'+'}</SizeButton>
              </RowBetween>
            </div>
            <PurpleButton label="Add to cart" style={{ background: Colors.black }} onClick={handleClickAddToCart} />
          </ColumnStart>
        </DetailWrapper>
      </ContainWrapper>
    </Container>
  );
};

export default ProductDetail;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 232px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
`;
const ContainWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 40px 20px;

  @media only screen and (max-width: 768px) {
    padding: 20px 20px;
    justify-content: center;
  }
`;
const MainImageWrapper = styled.div`
  min-width: 200px;
`;
const SubImageWrapper = styled.div<{ selected?: boolean }>`
  position: relative;
  max-width: 100px;
  height: auto;
  min-width: 30px;
  border: 1px solid ${({ selected }) => (selected ? Colors.black : 'transparent')};
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    max-width: 70px;
  }
`;
const ColumnStart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;
const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;
const RowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;
const Divider = styled.div`
  width: 100%;
  border: 2px solid ${Colors.gray};
`;
const SizeButton = styled.div<{ selected?: boolean }>`
  min-width: 40px;
  height: 40px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.black};
  background: ${({ selected }) => (selected ? Colors.black : Colors.white)};
  color: ${({ selected }) => (selected ? Colors.white : Colors.black)};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ColorButton = styled.div<{ selected?: boolean; bgColor?: string }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${Colors.black};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  // text-align: center;
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  cursor: ${({ isHover }) => (isHover ? 'pointer' : '')};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.4 : size < 20 ? size : size * 0.7}px`};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.5 : size < 20 ? size : size * 0.8}px`};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.7 : size < 20 ? size : size * 0.9}px`};
  }

  @media only screen and (min-width: 1200px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.9 : size < 20 ? size : size}px`};
  }

  @media only screen and (min-width: 1400px) {
    font-size: ${({ size }) => `${size}px`};
  }
`;
