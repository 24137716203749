import * as C from '../types/cart';

export interface ITokenData {
  id: number;
  name: string;
  description: string;
  src: string;
  price: any;
  size: string;
  color: string;
  quantity: number;
}

export interface IState {
  items: ITokenData[];
  total: any;
}

const initialState: IState = {
  items: [],
  total: 0,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case C.ADD_ITEM:
      return {
        items: [...state.items, action.payload.item],
        total: state.total + action.payload.item.price * action.payload.item.quantity,
      };
    case C.REMOVE_ITEM:
      return {
        items: [...state.items.filter((item, index) => index !== action.payload.index)],
        total: state.total - state.items[action.payload.index].price * state.items[action.payload.index].quantity,
      };
    case C.REMOVE_ALL_ITEM:
      return {
        items: [],
        total: 0,
      };
    case C.UPDATE_QUANTITY:
      break;
    default:
      return state;
  }
};

export default cardReducer;
