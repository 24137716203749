export const PRODUCT_LIST = [
  {
    id: 0,
    name: 'Laptop case',
    price: 30,
    color: ['#ffffff'],
    src: '/images/laptop-case.png',
    description: 'Travel and have your laptop safely secured in this Sphynx laptop cover.',
    new: true,
    category: 0,
    size: ['13”', '15”'],
    subImages: [
      '/images/laptop-case.png',
      '/images/laptop-case1.png',
    ],
  },
  {
    id: 1,
    name: 'AirPod cover',
    price: 25,
    color: ['#ffffff'],
    src: '/images/iPodCover2.jpg',
    description: 'Travel and have your Airpods safely secured in this Sphynx AirPods cover.',
    new: true,
    category: 0,
    size: ['Universal'],
    subImages: [
      '/images/iPodCover2.jpg',
      '/images/airPodCover.png',
      '/images/airPodCover1.png',
    ],
  },
  {
    id: 2,
    name: 'iPhone Cover',
    price: 30,
    color: ['#ffffff'],
    src: '/images/iPhoneCover.jpg',
    description: 'Travel and have your iPhones safely secured in this Sphynx iPhone cover.',
    new: true,
    category: 0,
    size: ['Universal'],
    subImages: [
      '/images/iPhoneCover.jpg',
      '/images/iPhoneCover1.jpg',
      '/images/iPhoneCover2.png',
      '/images/iPhoneCover3.png',
    ],
  },
  {
    id: 3,
    name: 'Hoodie',
    price: 75,
    color: ['#ffffff', '#000000'],
    src: '/images/hoodie.png',
    description: 'Stay cosy in this Sphynx hoodie all year long.',
    new: true,
    category: 0,
    size: ['S', 'M', 'L', 'XL'],
    subImages: ['/images/hoodie.png', '/images/hoodie1.jpg', '/images/hoodie2.jpg'],
  },
];
