import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';
import ProductCard from 'components/ProductCard';
import { ReactComponent as FiterIcon } from 'images/filter-icon.svg';
import { ReactComponent as CloseIcon } from 'images/close-icon.svg';
import { PRODUCT_LIST } from 'assets/mockup';
import toast from 'react-hot-toast';
import axios from 'axios';

const Product = () => {
  const history = useHistory();
  const [products, setProducts] = useState(PRODUCT_LIST);
  const [filterOptions, setFilterOptions] = useState([
    { label: 'Case', status: false, value: 0 },
    { label: 'Outfit', status: false, value: 1 },
    { label: 'Accessories', status: false, value: 2 },
    { label: 'T-shirt', status: false, value: 3 },
    { label: 'Pants', status: false, value: 4 },
  ]);
  const [openFilterBar, setOpenFilterBar] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    const getAllTransactions = async () => {
      try {
        let res: any = await axios.get(`${process.env.REACT_APP_BACKEND}/products`);
        res = res.data;
        console.log('getAllTransactions res : ', res);
        if (res.success) {
          let tmpArr = JSON.parse(res.data);
          const newArr = tmpArr.map(item => {
            return { ...item, products: JSON.parse(item?.products) };
          });
          setTransactionId(newArr[0].transaction_id);
          console.log('getAllTransactions : ', newArr);
        }
      } catch (err) {
        console.log('Error : getAllTransactions : ', err);
        toast.error(err?.response?.data?.message);
      }
    };
    getAllTransactions();
  }, []);

  useEffect(() => {
    const getOneTransaction = async () => {
      if (!transactionId) return;
      try {
        let res: any = await axios.get(`${process.env.REACT_APP_BACKEND}/product?transaction_id=${transactionId}`);
        res = res.data;
        console.log('getOneTransaction res : ', res);
        if (res.success) {
          let newObj = res.data;
          newObj = { ...newObj, products: JSON.parse(newObj.products) };
          console.log('getOneTransaction : ', newObj);
        }
      } catch (err) {
        console.log('Error : getOneTransaction : ', err);
        toast.error(err?.response?.data?.message);
      }
    };
    getOneTransaction();
  }, [transactionId]);

  useEffect(() => {
    const filteredOptions = filterOptions.filter(item => item.status === true);
    if (filteredOptions?.length === 0) {
      setProducts(PRODUCT_LIST);
    } else {
      const filterdList = PRODUCT_LIST?.filter(
        item => filteredOptions.map(option => option.value).indexOf(item.category) !== -1
      );
      setProducts(filterdList);
    }
  }, [filterOptions]);

  const handleChangeFilter = idx => {
    let _filterList = [...filterOptions];
    _filterList[idx].status = !_filterList[idx].status;
    setFilterOptions(_filterList);
  };

  return (
    <Container>
      <FixedFilterWrapper onClick={() => setOpenFilterBar(true)}>
        <FiterIcon style={{ cursor: 'pointer' }} width={20} height={20} />
      </FixedFilterWrapper>
      <FixedFillterBar open={openFilterBar}>
        <CategoryWrapper>
          <CloseIcon
            style={{ cursor: 'pointer', alignSelf: 'flex-end' }}
            width={30}
            height={30}
            onClick={() => setOpenFilterBar(!openFilterBar)}
          />
          <Text size={24} color={Colors.black} bold="700">
            Product (120)
          </Text>
          {filterOptions?.map((item, index) => (
            <CheckBox key={index}>
              <input
                type="checkbox"
                id={`${index}`}
                name={`${item?.label}`}
                onChange={() => handleChangeFilter(index)}
              />
              <Text size={16} color={Colors.black} bold="400">
                {item?.label}
              </Text>
            </CheckBox>
          ))}
        </CategoryWrapper>
      </FixedFillterBar>
      <ContainWrapper>
        <Row>
          {products?.map((item, index) => (
            <Column key={index} lg="3" md="4" sm="6" xs="12">
              <ProductCard item={item} />
            </Column>
          ))}
        </Row>
      </ContainWrapper>
    </Container>
  );
};

export default Product;

const getWidthString = span => {
  if (!span) return;

  let width = (span / 12) * 100;
  return `width: ${width}%;`;
};

const Row = styled.div`
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;
const Column = styled.div<{ xs?: any; sm?: any; md?: any; lg?: any }>`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-bottom: 20px;
  ${({ xs }) => (xs ? getWidthString(xs) : 'width: 100%')};

  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidthString(sm)}%;
  }
  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidthString(md)}%;
  }
  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidthString(lg)}%;
  }
`;
const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 232px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
`;
const ContainWrapper = styled.div`
  max-width: 1200px;
  padding: 40px 20px;
  width: 100%;
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  text-align: center;
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  cursor: ${({ isHover }) => (isHover ? 'pointer' : '')};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.4 : size < 20 ? size : size * 0.7}px`};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.5 : size < 20 ? size : size * 0.8}px`};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.7 : size < 20 ? size : size * 0.9}px`};
  }

  @media only screen and (min-width: 1200px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.9 : size < 20 ? size : size}px`};
  }

  @media only screen and (min-width: 1400px) {
    font-size: ${({ size }) => `${size}px`};
  }
`;
const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;
const CheckBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  & input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  & input[type='checkbox']:checked {
    accent-color: ${Colors.purple};
    color: ${Colors.white};
  }
`;
const FixedFilterWrapper = styled.div`
  position: fixed;
  left: 0;
  top: calc(50vh - 12px);
  padding: 8px 8px 6px 6px;
  background: white;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
`;
const FixedFillterBar = styled.div<{ open: boolean }>`
  position: fixed;
  left: ${({ open }) => (open ? '0px' : '-300px')};
  top: 81px;
  width: 300px;
  height: calc(100vh - 81px);
  background: ${Colors.white};
  transition: all 0.2s ease-in-out;
  z-index: 999;
  border-right: 1px solid ${Colors.gray};
`;
